import React from "react"
import { Alert, AlertDescription, AlertProps, AlertTitle, Center, HStack, IconButton, Text, useDisclosure } from "@chakra-ui/react"
import { Icon } from "./Icon"

type CustomAlertProps = AlertProps & {
  title?: string
  withoutIcon?: boolean
}

const CustomAlert: React.FC<CustomAlertProps> = ({ status, title, withoutIcon, ...props }) => {
  const { isOpen: isVisible, onClose } = useDisclosure({ defaultIsOpen: true })

  const bg = `functional.${status}`
  const color = `${bg}Content`

  if (status == "info") {
    return isVisible ? (
      <Alert status={status} {...props} backgroundColor="functional.success" display="grid" gridTemplateColumns="56px 1fr" p={0}>
        <Center backgroundColor="functional.successContent" color="background.white" p={4} alignSelf="stretch">
          <Icon name="information-circle" width="24" height="24" />
        </Center>
        <AlertDescription py={2.5} px={2} color="functional.successContent">
          {props.children}
        </AlertDescription>
      </Alert>
    ) : null
  } else {
    return isVisible ? (
      <Alert status={status} {...props} display="flex" justifyContent="space-between" backgroundColor={bg} color={color}>
        <HStack py={2.5} pl={4} pr={withoutIcon ? 4 : 0}>
          {title && (
            <AlertTitle as={Text} variant="largeParagraph" mr={0}>
              {title}
            </AlertTitle>
          )}
          <AlertDescription as={Text} variant="headlines">
            {props.children}
          </AlertDescription>
        </HStack>
        {!withoutIcon ? (
          <IconButton
            onClick={onClose}
            aria-label="Close Alert"
            variant="unstyled"
            icon={<Icon name="close" width="16" height="16" />}
            display="inline-flex"
            justifyContent="center"
            alignItems="center"
            w={12}
            h={12}
          />
        ) : null}
      </Alert>
    ) : null
  }
}

export default React.memo(CustomAlert)
