import React from "react"
import {
  Badge,
  Box,
  Button,
  Checkbox,
  CheckboxGroup,
  Container,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  HStack,
  Input,
  Radio,
  RadioGroup,
  Switch,
  Text,
  Textarea,
  VStack,
} from "@chakra-ui/react"
import type { PageProps } from "@root/types/global"
import { CustomSelect } from "./Input/CustomSelect"
import CustomAlert from "./CustomAlert"
import ButtonWithIcon from "./Input/ButtonWithIcon"

const StyleGuide: React.FC<PageProps<any>> = () => (
  <Container>
    <h1>Style Guide</h1>
    <Box p={4} bgColor="black" textColor="white" mt={4}>
      <Text>Headings</Text>
    </Box>

    <Heading as="h1" size="h1">
      Heading one
    </Heading>
    <Heading as="h2" size="h2">
      Heading two
    </Heading>
    <Heading as="h3" size="h3">
      Heading three
    </Heading>
    <Heading as="h4" size="h4">
      Heading four
    </Heading>
    <Heading as="h5" size="h5">
      Heading five
    </Heading>

    <Box p={4} bgColor="black" textColor="white" mt={4}>
      <Text>Text</Text>
    </Box>

    <Text size="largeParagraph" mb={4}>
      Large Paragraph - Lorem ipsum dolor sit amet, consectetur adipiscing elit. Eget mi, viverra fames ultricies lectus. Diam, eros ac nec
      scelerisque faucibus faucibus at. Senectus viverra eu lacus in quis adipiscing blandit dignissim scelerisque.
    </Text>

    <Text size="mediumParagraph" mb={4}>
      Medium Paragraph - Lorem ipsum dolor sit amet, consectetur adipiscing elit. Eget mi, viverra fames ultricies lectus. Diam, eros ac nec
      scelerisque faucibus faucibus at. Senectus viverra eu lacus in quis adipiscing blandit dignissim scelerisque.
    </Text>

    <Text size="mediumParagraph" variant="underlined" mb={4}>
      Medium Paragraph Underlined - Lorem ipsum dolor sit amet, consectetur adipiscing elit. Eget mi, viverra fames ultricies lectus. Diam,
      eros ac nec scelerisque faucibus faucibus at. Senectus viverra eu lacus in quis adipiscing blandit dignissim scelerisque.
    </Text>

    <Text size="smallParagraph" mb={4}>
      Small Paragraph - Lorem ipsum dolor sit amet, consectetur adipiscing elit. Eget mi, viverra fames ultricies lectus. Diam, eros ac nec
      scelerisque faucibus faucibus at. Senectus viverra eu lacus in quis adipiscing blandit dignissim scelerisque.
    </Text>

    <Box p={4} bgColor="black" textColor="white" mb={4}>
      <Text>Labels</Text>
    </Box>

    <Text size="button">Button</Text>

    <Text size="textLinkLarge" mb={4}>
      Text Link Large
    </Text>

    <Text size="textLinkMedium" mb={4}>
      Text Link Medium
    </Text>

    <Text size="textLinkSmall" mb={4}>
      Text Link Small
    </Text>

    <Text size="headlines" mb={4}>
      Headlines
    </Text>

    <Text size="smSemiSmall" mb={4}>
      SM Semi Small
    </Text>

    <Text size="smSemiLarge" mb={4}>
      SM Semi Large
    </Text>

    <Text size="labels" mb={4}>
      Labels
    </Text>

    <Text size="labels" variant="underlined" mb={4}>
      Labels Underlined
    </Text>

    <Box p={4} bgColor="black" textColor="white" my={4}>
      <Text>Text Inputs</Text>
    </Box>

    <FormControl variant="floating" mb={4}>
      <Input placeholder=" " />
      <FormLabel>Default field</FormLabel>
    </FormControl>

    <Input placeholder="Default field" mb={4} />

    <FormControl variant="floating" mb={4} isInvalid>
      <Input placeholder=" " />
      <FormLabel>Default field</FormLabel>
      <FormErrorMessage>Error related info text</FormErrorMessage>
    </FormControl>

    <FormControl variant="floating" mb={4} isDisabled>
      <Input placeholder=" " />
      <FormLabel>Disabled field</FormLabel>
    </FormControl>

    <Input placeholder="Disabled field" mb={4} isDisabled />

    <Box p={4} bgColor="black" textColor="white" my={4}>
      <Text>Text Areas</Text>
    </Box>

    <FormControl mb={4}>
      <Textarea placeholder=" " />
      <FormLabel>Input label</FormLabel>
    </FormControl>

    <Textarea placeholder="Input label" mb={4} />

    <FormControl mb={4} isInvalid>
      <Textarea placeholder=" " />
      <FormLabel>Input label</FormLabel>
      <FormErrorMessage>Error related info text</FormErrorMessage>
    </FormControl>

    <Textarea placeholder="Input label" mb={4} isInvalid />

    <FormControl mb={4}>
      <Textarea placeholder=" " isDisabled />
      <FormLabel>Input label</FormLabel>
    </FormControl>

    <Textarea placeholder="Input label" mb={4} isDisabled />

    <Box p={4} bgColor="black" textColor="white" my={4}>
      <Text>Selects</Text>
    </Box>

    <CustomSelect mb={4}>
      <option value="1">Option one</option>
      <option value="2">Option two</option>
      <option value="3">Option three</option>
      <option value="4">Option four</option>
    </CustomSelect>

    <FormControl mb={4} isInvalid>
      <CustomSelect>
        <option value="1">Option one</option>
        <option value="2">Option two</option>
        <option value="3">Option three</option>
        <option value="4">Option four</option>
      </CustomSelect>
      <FormErrorMessage>Error related info text</FormErrorMessage>
    </FormControl>

    <CustomSelect mb={4} isInvalid>
      <option value="1">Option one</option>
      <option value="2">Option two</option>
      <option value="3">Option three</option>
      <option value="4">Option four</option>
    </CustomSelect>

    <CustomSelect mb={4} isDisabled>
      <option value="1">Option one</option>
      <option value="2">Option two</option>
      <option value="3">Option three</option>
      <option value="4">Option four</option>
    </CustomSelect>

    <Box p={4} bgColor="black" textColor="white" my={4}>
      <Text>Radio Buttons</Text>
    </Box>

    <RadioGroup defaultValue="2" name="test" mb={4} display="flex" flexDir="column" gap={4}>
      <Radio value="1">Radio Off</Radio>
      <Radio value="2">Radio On</Radio>
    </RadioGroup>

    <RadioGroup defaultValue="2" name="test-disabled" mb={4} display="flex" flexDir="column" gap={4} isDisabled>
      <Radio value="1">Radio Disabled Off</Radio>
      <Radio value="2">Radio Disabled On</Radio>
    </RadioGroup>

    <Box p={4} bgColor="black" textColor="white" my={4}>
      <Text>Checkboxes</Text>
    </Box>

    <CheckboxGroup defaultValue={["2"]}>
      <VStack mb={4} display="flex" flexDir="column" alignItems="flex-start" gap={4}>
        <Checkbox value="1">Checkbox Off</Checkbox>
        <Checkbox value="2">Checkbox On</Checkbox>
      </VStack>
    </CheckboxGroup>

    <CheckboxGroup defaultValue={["2"]} isDisabled>
      <VStack mb={4} display="flex" flexDir="column" alignItems="flex-start" gap={4}>
        <Checkbox value="1">Checkbox Disabled Off</Checkbox>
        <Checkbox value="2">Checkbox Disabled On</Checkbox>
      </VStack>
    </CheckboxGroup>

    <Box p={4} bgColor="black" textColor="white" my={4}>
      <Text>Toggles (Switches)</Text>
    </Box>

    <FormControl variant="switchContainer" mb={4}>
      <Switch id="test-off1" />
      <FormLabel htmlFor="test-off1">Toggle Off</FormLabel>
    </FormControl>

    <FormControl variant="switchContainer" mb={4}>
      <Switch id="test-on1" isChecked />
      <FormLabel htmlFor="test-off1">Toggle On</FormLabel>
    </FormControl>

    <FormControl variant="switchContainer" mb={4} isDisabled>
      <Switch id="test-off2" />
      <FormLabel htmlFor="test-off2">Toggle Disabled Off</FormLabel>
    </FormControl>

    <FormControl variant="switchContainer" mb={4} isDisabled>
      <Switch id="test-on2" isChecked />
      <FormLabel htmlFor="test-off2">Toggle Disabled On</FormLabel>
    </FormControl>

    <Box p={4} bgColor="black" textColor="white" my={4}>
      <Text>Buttons Primary (lg)</Text>
    </Box>

    <VStack spacing={4} mb={4} alignItems="flex-start">
      <Button size="lg">Button Default</Button>
      <Button variant="outline" size="lg">
        Button Default
      </Button>
      <Button variant="solidSecondary" size="lg">
        Button Default
      </Button>
      <Button variant="outlineSecondary" size="lg">
        Button Default
      </Button>
      <Box bg="#000000" w="100%" p={4}>
        <Button variant="outlineInverted" size="lg">
          Button Default
        </Button>
      </Box>
    </VStack>

    <Box p={4} bgColor="black" textColor="white" my={4}>
      <Text>Buttons Secondary (md - default)</Text>
    </Box>

    <VStack spacing={4} mb={4} alignItems="flex-start">
      <Button>Button Default</Button>
      <Button variant="outline">Button Default</Button>
      <Button variant="solidSecondary">Button Default</Button>
      <Button variant="outlineSecondary">Button Default</Button>
      <Box bg="#000000" w="100%" p={4}>
        <Button variant="outlineInverted">Button Default</Button>
      </Box>
    </VStack>

    <Box p={4} bgColor="black" textColor="white" my={4}>
      <Text>Buttons Secondary (with icon)</Text>
    </Box>

    <VStack spacing={4} mb={4} alignItems="flex-start">
      <ButtonWithIcon variant="solidSecondary" iconName="arrows/chevron-right">
        Button Default
      </ButtonWithIcon>

      <ButtonWithIcon variant="solidSecondary" iconName="ui/cloud-upload" size="lg">
        Button Default
      </ButtonWithIcon>
    </VStack>

    <Box p={4} bgColor="black" textColor="white" my={4}>
      <Text>Badges</Text>
    </Box>

    <HStack spacing={4} mb={4}>
      <Badge size="large" variant="primary">
        New Arrival
      </Badge>
      <Badge size="large" variant="secondary">
        Popular
      </Badge>
      <Badge size="large" variant="tertiary">
        On Sale
      </Badge>
    </HStack>

    <HStack spacing={4} mb={4}>
      <Badge variant="primary">New Arrival</Badge>
      <Badge variant="secondary">Popular</Badge>
      <Badge variant="tertiary">On Sale</Badge>
    </HStack>

    <Box p={4} bgColor="black" textColor="white" my={4}>
      <Text>Alerts</Text>
    </Box>

    <CustomAlert title="Holy smokes!" status="error" mb={4}>
      Something seriously bad happened.
    </CustomAlert>

    <CustomAlert title="Be warned!" status="warning" mb={4}>
      Something not ideal might happen.
    </CustomAlert>

    <CustomAlert title="Success!" status="success" mb={4}>
      Something went exactly as planned.
    </CustomAlert>

    <CustomAlert status="info" mb={4}>
      Something happened that you should know about.
    </CustomAlert>
  </Container>
)

export default React.memo(StyleGuide)
